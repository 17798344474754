import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../context/UserContext";
import AlertContext from "../../../context/AlertContext";
import { PrecioFormateado } from "../../ElementsUi/PriceFormat";
import { useActions } from "../../../permissions/useActions";
import { ModalOptionsOpi } from "../Options/Opi/ModalOptionsOpi";
import { Alert } from "../../Alerts";
import WaitMessageContext from "../../../context/WaitMessageContext";

export const ViewModalOrdenPedidoInternaDeposito = ({ id, ruta, acciones }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const { isAllowed } = useActions(acciones)
    const { url, user, workstationActive } = useContext(UserContext);
    const { setWait, setWaitMessage } = useContext(WaitMessageContext);
    const { setStatusCode, setResponseText } = useContext(AlertContext);

    const openViewModal = async () => {
        setWait(true)
        setWaitMessage("Espere . . .")
        await axios
            .post(url + ruta + "/readonly/" + id, {
                Usuario: user.UsuarioKey,
                Workstation: workstationActive.WorkstationKey,
            })
            .then(function (response) {
                console.log(response);
                setWait(false)
                if (response.status === 200) {
                    setOpen(true);
                    setData(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.data && "Motivo" in error.response.data) {
                    setResponseText(error.response.data["Motivo"]);
                    setStatusCode(error.response.status);
                    setTimeout(() => {
                        setResponseText("");
                    }, 5000);
                    setWait(false);
                } else {
                    setResponseText("");
                    setTimeout(() => {
                        setWaitMessage(
                            "Hubo un error. Presione el botón para recargar el sitio"
                        );
                    }, 1000);
                }
            });
    };

    const [currentTab, setCurrentTab] = useState("1");

    const tabs = [
        {
            id: 1,
            tabTitle: "tab 1",
            title: "Datos de la orden",
        },
        {
            id: 2,
            tabTitle: "tab 2",
            title: "Artículos",
        },
        {
            id: 3,
            tabTitle: "tab 3",
            title: "Características",
        },
        {
            id: 4,
            tabTitle: "tab 4",
            title: "Datos adicionales",
        },
        {
            id: 5,
            tabTitle: "tab 5",
            title: "Gestión",
        },
    ];

    const handleTabClick = ({ target }) => {
        const { id } = target;
        setCurrentTab(id);
    };

    const handleTabChange = () => {
        let tabs = document.getElementById("tabs");
        setCurrentTab(tabs.options[tabs.selectedIndex].value);
    }

    useEffect(() => {
        if (!open) {
            setCurrentTab("1");
        }
    }, [open]);

    return (
        <>
            <div className="tooltip">
                <button
                    className="inline-flex items-center "
                    onClick={openViewModal}
                >
                    <span className="tooltiptext">Ver</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-indigo-600 hover:text-indigo-900"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                </button>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="md:flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="bg-white h-full md:max-h-full opacity-100 overflow-auto pb-10 px-4 py-5 relative rounded-lg shadow-xl sm:p-6 sm:scale-100 sm:w-5/6 text-left transform transition-all translate-y-0">
                                    <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none mt-3"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full text-center">
                                        <p className="text-medium font-bold leading-7 text-gray-800 text-left md:text-center">
                                            Orden de pedido por depósito Nº{" "}
                                            {data.Numero}
                                        </p>
                                    </div>
                                    <Alert />
                                    <div className="sm:hidden mb-5">
                                        <label
                                            htmlFor="tabs"
                                            className="sr-only"
                                        >
                                            Select a tab
                                        </label>
                                        <select
                                            id="tabs"
                                            onChange={handleTabChange}
                                            className="mt-2 block w-full border-0 py-1.5 pl-3 pr-10 rounded-md border-gray-300"
                                        >
                                            {tabs.map((tab) => (
                                                <option
                                                    className="relative cursor-default select-none py-2 pl-8 pr-4"
                                                    value={tab.id}
                                                    key={tab.title}
                                                >
                                                    {tab.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="sm:block hidden border-b border-gray-200">
                                        <nav
                                            className="-mb-px flex"
                                            aria-label="Tabs"
                                        >
                                            {tabs.map((tab, i) => (
                                                <button
                                                    key={i}
                                                    id={tab.id}
                                                    className={`w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                                                        currentTab ===
                                                        `${tab.id}`
                                                            ? "border-indigo-500 text-indigo-600"
                                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                                    }`}
                                                    onClick={handleTabClick}
                                                >
                                                    {tab.title}
                                                </button>
                                            ))}
                                        </nav>
                                    </div>
                                    <div className="max-h-96 h-96">
                                        {(() => {
                                            if (currentTab === "1") {
                                                return (
                                                    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                            <dl className="sm:grid grid-cols-6 gap-x-4 gap-y-8 sm:grid-cols-2 space-y-5">
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Fecha
                                                                        orden
                                                                        de
                                                                        pedido
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.FechaPedido ===
                                                                        null
                                                                            ? ""
                                                                            : data.FechaPedido &&
                                                                                Moment(
                                                                                    data.FechaPedido
                                                                                ).format(
                                                                                    "DD-MM-YYYY"
                                                                                )}
                                                                    </dd>
                                                                </div>
                                                                {/* <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Fecha
                                                                        entrega
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.FechaEntrega ===
                                                                        null
                                                                            ? ""
                                                                            : data.FechaEntrega &&
                                                                                Moment(
                                                                                    data.FechaEntrega
                                                                                ).format(
                                                                                    "DD-MM-YYYY"
                                                                                )}
                                                                    </dd>
                                                                </div> */}
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Fecha
                                                                        vencimiento
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.FechaVencimiento ===
                                                                        null
                                                                            ? ""
                                                                            : data.FechaVencimiento &&
                                                                                Moment(
                                                                                    data.FechaVencimiento
                                                                                ).format(
                                                                                    "DD-MM-YYYY"
                                                                                )}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Número
                                                                        orden
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Numero ===
                                                                        null
                                                                            ? ""
                                                                            : data.Numero &&
                                                                                data.Numero}
                                                                    </dd>
                                                                </div>
                                                            </dl>
                                                            <hr className="my-5" />
                                                            <dl className="sm:grid gap-x-4 gap-y-4 sm:grid-cols-2 md:space-y-0 space-y-5">
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Deposito
                                                                        que
                                                                        envia
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.DepositoEnvia ===
                                                                        null
                                                                            ? ""
                                                                            : data.DepositoEnvia &&
                                                                                data.DepositoEnvia}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Deposito
                                                                        que
                                                                        recibe
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.DepositoRecibe ===
                                                                        null
                                                                            ? ""
                                                                            : data.DepositoRecibe &&
                                                                                data.DepositoRecibe}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Centro
                                                                        de
                                                                        costos
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.CentroCosto ===
                                                                        null
                                                                            ? ""
                                                                            : data.CentroCosto &&
                                                                                data
                                                                                    .CentroCosto
                                                                                    .Nombre}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Empleado
                                                                        designado
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Empleado ===
                                                                        null
                                                                            ? ""
                                                                            : data.Empleado &&
                                                                                data
                                                                                    .Empleado
                                                                                    .RazonSocial}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Estado
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Estado ===
                                                                        null
                                                                            ? ""
                                                                            : data.Estado &&
                                                                                data
                                                                                    .Estado
                                                                                    .Nombre}
                                                                    </dd>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                );
                                            } else if (currentTab === "2") {
                                                return (
                                                    <div className="h-96 max-h-96 overflow-y-auto">
                                                        <table className="sticky w-full divide-y divide-indigo-600">
                                                            <thead className="bg-gray-100 sticky top-0 z-10">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 text-left text-sm font-semibold text-gray-900"
                                                                    >
                                                                        Cant.
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                                                    >
                                                                        Nombre
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                                                    >
                                                                        Unitario
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                                                    >
                                                                        Dto.
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                                                    >
                                                                        P.Total
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="sticky max-h-40 overflow-y-scroll">
                                                                {data.Items.map(
                                                                    (
                                                                        item,
                                                                        i
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                i
                                                                            }
                                                                            className="border-b border-gray-200"
                                                                        >
                                                                            <td className="mt-0.5 text-sm text-gray-600 pl-4">
                                                                                {
                                                                                    item
                                                                                        .ItemOperacion
                                                                                        .Cantidad
                                                                                }
                                                                            </td>
                                                                            <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                                                <div className="text-gray-600">
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .Item
                                                                                            .Nombre
                                                                                    }
                                                                                </div>
                                                                                <div className="mt-0.5 text-gray-600 sm:hidden">
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .Cantidad
                                                                                    }{" "}
                                                                                    cant
                                                                                    item
                                                                                    por{" "}
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PrecioUnitarioFinalShowMoneda
                                                                                    }{" "}
                                                                                    con
                                                                                    dto.
                                                                                    de{" "}
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PorcentajeDescuentoShowMoneda
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className="hidden py-4 px-3 text-right text-sm text-gray-600 sm:table-cell">
                                                                                <PrecioFormateado
                                                                                    moneda={
                                                                                        data
                                                                                            .Operacion
                                                                                            .Moneda
                                                                                    }
                                                                                    precio={
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PrecioUnitarioFinalShowMoneda
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td className="hidden py-4 px-3 text-right text-sm text-gray-600 sm:table-cell">
                                                                                <PrecioFormateado
                                                                                    precio={
                                                                                        "% " +
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PorcentajeDescuento
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-600 sm:pr-6 md:pr-0">
                                                                                <PrecioFormateado
                                                                                    moneda={
                                                                                        data
                                                                                            .Operacion
                                                                                            .Moneda
                                                                                    }
                                                                                    precio={
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PrecioTotalFinalShowMoneda
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th
                                                                        scope="row"
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                        className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
                                                                    >
                                                                        Subtotal
                                                                    </th>
                                                                    <th
                                                                        scope="row"
                                                                        className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-800 sm:hidden"
                                                                    >
                                                                        Subtotal
                                                                    </th>
                                                                    <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-800 sm:pr-6 md:pr-0">
                                                                        <PrecioFormateado
                                                                            moneda={
                                                                                data
                                                                                    .Operacion
                                                                                    .Moneda
                                                                            }
                                                                            precio={
                                                                                data
                                                                                    .Operacion
                                                                                    .SubtotalShowMoneda
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th
                                                                        scope="row"
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                        className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
                                                                    >
                                                                        Monto
                                                                        IVA
                                                                    </th>
                                                                    <th
                                                                        scope="row"
                                                                        className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-800 sm:hidden"
                                                                    >
                                                                        Monto
                                                                        IVA
                                                                    </th>
                                                                    <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-800 sm:pr-6 md:pr-0">
                                                                        <PrecioFormateado
                                                                            moneda={
                                                                                data
                                                                                    .Operacion
                                                                                    .Moneda
                                                                            }
                                                                            precio={
                                                                                data
                                                                                    .Operacion
                                                                                    .MontoIVAShowMoneda
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th
                                                                        scope="row"
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                        className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                                                                    >
                                                                        Total
                                                                    </th>
                                                                    <th
                                                                        scope="row"
                                                                        className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                                                                    >
                                                                        Total
                                                                    </th>
                                                                    <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                                                                        <PrecioFormateado
                                                                            moneda={
                                                                                data
                                                                                    .Operacion
                                                                                    .Moneda
                                                                            }
                                                                            precio={
                                                                                data
                                                                                    .Operacion
                                                                                    .ImporteTotalShowMoneda
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                );
                                            } else if (currentTab === "3") {
                                                return (
                                                    <>
                                                        {data.Operacion
                                                            .Caracteristicas
                                                            .length ===
                                                        0 ? (
                                                            <div className="text-sm font-medium text-gray-500 w-full mx-auto text-center mt-10">
                                                                No hay
                                                                caracteristicas
                                                                disponibles
                                                            </div>
                                                        ) : (
                                                            data.Operacion.Caracteristicas.map(
                                                                (c, i) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                i
                                                                            }
                                                                            className="border-b border-gray-200 bg-white px-4 py-2 sm:px-6"
                                                                        >
                                                                            <h4 className="text-base font-medium leading-6 text-gray-900">
                                                                                {
                                                                                    c.Caracteristica
                                                                                }
                                                                            </h4>
                                                                            <p className="mt-1 text-sm text-gray-500">
                                                                                {
                                                                                    c.Nombre
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                        )}
                                                    </>
                                                );
                                            } else if (currentTab === "4") {
                                                return (
                                                    <div>
                                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Motivo
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-600 sm:col-span-2 sm:mt-0 italic">
                                                                {data.Motivo ===
                                                                ""
                                                                    ? "No tiene motivo esta orden"
                                                                    : JSON.stringify(
                                                                            data.Motivo
                                                                        )}
                                                            </dd>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <div className="w-full text-xl text-center font-bold">
                                                            <h1>
                                                                Opciones
                                                            </h1>
                                                        </div>
                                                        <hr className="my-1.5 w-10 mx-auto border-2 border-indigo-700" />
                                                        <ul>
                                                            {/* {isAllowed("OPERACION_ORDEN_PEDIDO_CAMBIAR_ESTADO") && (
                                                                )} */}
                                                            <ModalOptionsOpi
                                                                action={`${ruta}/change_state/`}
                                                                actionText={
                                                                    "Cambiar estado de la orden"
                                                                }
                                                                item={data}
                                                                requestEndpoint={`${ruta}/states`}
                                                                requestAction={
                                                                    "cambio_estado"
                                                                }
                                                                id={id}
                                                                requestSvg={
                                                                    "change"
                                                                }
                                                                handleData={
                                                                    setData
                                                                }
                                                            />
                                                            <ModalOptionsOpi
                                                                actionText={
                                                                    "Ver PDF en nueva ventana"
                                                                }
                                                                item={data}
                                                                id={id}
                                                                requestEndpoint={`${ruta}/print_rave/${id}`}
                                                                requestAction={
                                                                    "previewPDF"
                                                                }
                                                                requestSvg={
                                                                    "pdf"
                                                                }
                                                            />
                                                        </ul>
                                                    </>
                                                );
                                            }
                                        })()}
                                    </div>
                                    <div className="mt-5 sm:mt-10 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(!open)}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
