import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Moment from "moment";
import UserContext from "../../../context/UserContext";
import { PrecioFormateado } from "../../ElementsUi/PriceFormat";
import { Alert } from "../../Alerts";


export const ViewModalPresupuestoCliente = ({ id, ruta }) => {
    const [open, setOpen] = useState(false);
    const { url, user, workstationActive, headers } = useContext(UserContext);
    const [data, setData] = useState([]);

    const openViewModal = async () => {
        await axios
            .post(url + ruta + "/readonly/" + id, {
                // headers: headers
                Workstation: workstationActive.WorkstationKey,
                Usuario: user.UsuarioKey,
            })
            .then(function (response) {
                if (response.status === 200) {
                    setOpen(true);
                    // console.log(response.data);
                    setData(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const [currentTab, setCurrentTab] = useState("1");

    const tabs = [
        {
            id: 1,
            tabTitle: "tab 1",
            title: "Datos del Presupuesto",
        },
        {
            id: 2,
            tabTitle: "tab 2",
            title: "Artículos",
        },
        {
            id: 3,
            tabTitle: "tab 3",
            title: "Características",
        },
        {
            id: 4,
            tabTitle: "tab 4",
            title: "Datos adicionales",
        },
        {
            id: 5,
            tabTitle: "tab 5",
            title: "Gestión",
        },
    ];

    const handleTabChange = () => {
        let tabs = document.getElementById("tabs");
        setCurrentTab(tabs.options[tabs.selectedIndex].value);
    };

    const handleTabClick = ({ target }) => {
        const { id } = target;
        setCurrentTab(id);
    };

    useEffect(() => {
        if (!open) {
            setCurrentTab("1");
        }
    }, [open]);


    return (
        <>
            <div className="tooltip">
                <button
                    className="inline-flex items-center "
                    onClick={openViewModal}
                >
                    <span className="tooltiptext">Ver</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-indigo-600 hover:text-indigo-900"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                </button>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-5/6 sm:p-6 max-h-full h-[38rem]">
                                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full text-center">
                                        <p className="text-medium font-bold leading-7 text-gray-800 text-center">
                                            Presupuesto Nº {data.Numero}
                                        </p>
                                    </div>
                                    <Alert />
                                    <div className="sm:hidden mb-5">
                                        <label
                                            htmlFor="tabs"
                                            className="sr-only"
                                        >
                                            Select a tab
                                        </label>
                                        <select
                                            id="tabs"
                                            onChange={handleTabChange}
                                            className="mt-2 block w-full border-0 py-1.5 pl-3 pr-10 rounded-md border-gray-300"
                                        >
                                            {tabs.map((tab) => (
                                                <option
                                                    className="relative cursor-default select-none py-2 pl-8 pr-4"
                                                    value={tab.id}
                                                    key={tab.title}
                                                >
                                                    {tab.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="sm:block hidden border-b border-gray-200">
                                        <nav
                                            className="-mb-px flex"
                                            aria-label="Tabs"
                                        >
                                            {tabs.map((tab, i) => (
                                                <button
                                                    key={i}
                                                    id={tab.id}
                                                    className={`w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                                                        currentTab ===
                                                        `${tab.id}`
                                                            ? "border-indigo-500 text-indigo-600"
                                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                                    }`}
                                                    onClick={handleTabClick}
                                                >
                                                    {tab.title}
                                                </button>
                                            ))}
                                        </nav>
                                    </div>
                                    <div className="contents">
                                        {(() => {
                                            if (currentTab === "1") {
                                                return (
                                                    <div className="overflow-hidden mt-4 bg-white shadow sm:rounded-b-lg">
                                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                            <dl className="grid grid-cols-6 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Cliente
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Cliente ===
                                                                        null
                                                                            ? ""
                                                                            : data.Cliente &&
                                                                                data
                                                                                    .Cliente
                                                                                    .RazonSocial}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Vendedor
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Vendedor ===
                                                                        null
                                                                            ? ""
                                                                            : data.Vendedor &&
                                                                                data
                                                                                    .Vendedor
                                                                                    .RazonSocial}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Condición
                                                                        pago
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.CondicionPago ===
                                                                        null
                                                                            ? ""
                                                                            : data.CondicionPago &&
                                                                                data
                                                                                    .CondicionPago
                                                                                    .Nombre}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Lista
                                                                        Precio
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Operacion ===
                                                                        null
                                                                            ? ""
                                                                            : data.Operacion &&
                                                                                data
                                                                                    .Operacion
                                                                                    .ListaPrecios
                                                                                    .Nombre}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Depósito
                                                                        de
                                                                        entrega
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.DepositoEntregaCodigo ===
                                                                        null
                                                                            ? ""
                                                                            : data.DepositoEntregaCodigo &&
                                                                                data.DepositoEntregaCodigo}
                                                                    </dd>
                                                                </div>
                                                                {/* <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Sector
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Sector ===
                                                                        null
                                                                            ? ""
                                                                            : data.Sector &&
                                                                                data
                                                                                    .Sector
                                                                                    .Nombre}
                                                                    </dd>
                                                                </div> */}
                                                            </dl>
                                                            <hr className="my-2" />
                                                            <dl className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Fecha
                                                                        Presupuesto
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.FechaPresupuesto ===
                                                                        null
                                                                            ? ""
                                                                            : data.FechaPresupuesto &&
                                                                                Moment(
                                                                                    data.FechaPresupuesto
                                                                                ).format(
                                                                                    "DD-MM-YYYY"
                                                                                )}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Fecha
                                                                        entrega
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.FechaEntrega ===
                                                                        null
                                                                            ? ""
                                                                            : data.FechaEntrega &&
                                                                                Moment(
                                                                                    data.FechaEntrega
                                                                                ).format(
                                                                                    "DD-MM-YYYY"
                                                                                )}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Fecha
                                                                        vencimiento
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.FechaVencimiento ===
                                                                        null
                                                                            ? ""
                                                                            : data.FechaVencimiento &&
                                                                                Moment(
                                                                                    data.FechaVencimiento
                                                                                ).format(
                                                                                    "DD-MM-YYYY"
                                                                                )}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Número
                                                                        orden
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Numero ===
                                                                        null
                                                                            ? ""
                                                                            : data.Numero &&
                                                                                data.Numero}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Numero
                                                                        OC
                                                                        Cliente
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.NumeroEntidad ===
                                                                        null
                                                                            ? ""
                                                                            : data.NumeroEntidad &&
                                                                                data.NumeroEntidad}
                                                                    </dd>
                                                                </div>
                                                                <div className="sm:col-span-1">
                                                                    <dt className="text-sm font-semibold text-indigo-600">
                                                                        Prioridad
                                                                    </dt>
                                                                    <dd className="mt-1 text-sm text-gray-900">
                                                                        {data.Prioridad ===
                                                                        null
                                                                            ? ""
                                                                            : data.Prioridad &&
                                                                                data.Prioridad ===
                                                                                    "1"
                                                                            ? "Baja"
                                                                            : data.Prioridad &&
                                                                                data.Prioridad ===
                                                                                    "2"
                                                                            ? "Media"
                                                                            : "Alta"}
                                                                    </dd>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                );
                                            } else if (currentTab === "2") {
                                                return (
                                                    <div className="h-96 mt-4 max-h-96 overflow-y-auto">
                                                        <table className="sticky w-full divide-y divide-indigo-600">
                                                            <thead className="bg-gray-100 sticky top-0 z-10">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 text-left text-sm font-semibold text-gray-900"
                                                                    >
                                                                        Cant.
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                                                    >
                                                                        Nombre
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                                                    >
                                                                        Unitario
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                                                    >
                                                                        Dto.
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                                                    >
                                                                        P.Total
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="sticky max-h-40 overflow-y-scroll">
                                                                {data.Items.map(
                                                                    (
                                                                        item,
                                                                        i
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                i
                                                                            }
                                                                            className="border-b border-gray-200"
                                                                        >
                                                                            <td className="mt-0.5 text-sm text-gray-600 pl-4">
                                                                                {
                                                                                    item
                                                                                        .ItemOperacion
                                                                                        .Cantidad
                                                                                }
                                                                            </td>
                                                                            <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                                                <div className="text-gray-600">
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .Item
                                                                                            .Nombre
                                                                                    }
                                                                                </div>
                                                                                <div className="mt-0.5 text-gray-600 sm:hidden">
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .Cantidad
                                                                                    }{" "}
                                                                                    cant
                                                                                    item
                                                                                    por{" "}
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PrecioUnitarioFinalShowMoneda
                                                                                    }{" "}
                                                                                    con
                                                                                    dto.
                                                                                    de{" "}
                                                                                    {
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PorcentajeDescuentoShowMoneda
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className="hidden py-4 px-3 text-right text-sm text-gray-600 sm:table-cell">
                                                                                <PrecioFormateado
                                                                                    moneda={
                                                                                        data
                                                                                            .Operacion
                                                                                            .Moneda
                                                                                    }
                                                                                    precio={
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PrecioUnitarioFinalShowMoneda
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td className="hidden py-4 px-3 text-right text-sm text-gray-600 sm:table-cell">
                                                                                <PrecioFormateado
                                                                                    precio={
                                                                                        "% " +
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PorcentajeDescuento
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-600 sm:pr-6 md:pr-0">
                                                                                <PrecioFormateado
                                                                                    moneda={
                                                                                        data
                                                                                            .Operacion
                                                                                            .Moneda
                                                                                    }
                                                                                    precio={
                                                                                        item
                                                                                            .ItemOperacion
                                                                                            .PrecioTotalFinalShowMoneda
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th
                                                                        scope="row"
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                        className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
                                                                    >
                                                                        Subtotal
                                                                    </th>
                                                                    <th
                                                                        scope="row"
                                                                        className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-800 sm:hidden"
                                                                    >
                                                                        Subtotal
                                                                    </th>
                                                                    <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-800 sm:pr-6 md:pr-0">
                                                                        <PrecioFormateado
                                                                            moneda={
                                                                                data
                                                                                    .Operacion
                                                                                    .Moneda
                                                                            }
                                                                            precio={
                                                                                data
                                                                                    .Operacion
                                                                                    .SubtotalShowMoneda
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th
                                                                        scope="row"
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                        className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-800 sm:table-cell md:pl-0"
                                                                    >
                                                                        Monto
                                                                        IVA
                                                                    </th>
                                                                    <th
                                                                        scope="row"
                                                                        className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-800 sm:hidden"
                                                                    >
                                                                        Monto
                                                                        IVA
                                                                    </th>
                                                                    <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-800 sm:pr-6 md:pr-0">
                                                                        <PrecioFormateado
                                                                            moneda={
                                                                                data
                                                                                    .Operacion
                                                                                    .Moneda
                                                                            }
                                                                            precio={
                                                                                data
                                                                                    .Operacion
                                                                                    .MontoIVAShowMoneda
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th
                                                                        scope="row"
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                        className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                                                                    >
                                                                        Total
                                                                    </th>
                                                                    <th
                                                                        scope="row"
                                                                        className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                                                                    >
                                                                        Total
                                                                    </th>
                                                                    <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                                                                        <PrecioFormateado
                                                                            moneda={
                                                                                data
                                                                                    .Operacion
                                                                                    .Moneda
                                                                            }
                                                                            precio={
                                                                                data
                                                                                    .Operacion
                                                                                    .ImporteTotalShowMoneda
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                );
                                            } else if (currentTab === "3") {
                                                return (
                                                    <>
                                                        {data.Operacion.Caracteristicas.map(
                                                            (c, i) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            i
                                                                        }
                                                                        className="border-b border-gray-200 bg-white px-4 py-2 sm:px-6"
                                                                    >
                                                                        <h4 className="text-base font-medium leading-6 text-gray-900">
                                                                            {
                                                                                c.Caracteristica
                                                                            }
                                                                        </h4>
                                                                        <p className="mt-1 text-sm text-gray-500">
                                                                            {
                                                                                c.Nombre
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <div>
                                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Notas
                                                                públicas
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-600 sm:col-span-2 sm:mt-0 italic">
                                                                {data
                                                                    .Operacion
                                                                    .Notas ===
                                                                ""
                                                                    ? "No tiene notas públicas"
                                                                    : JSON.stringify(
                                                                            data
                                                                                .Operacion
                                                                                .Notas
                                                                        )}
                                                            </dd>
                                                        </div>
                                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Notas
                                                                privadas
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-600 sm:col-span-2 sm:mt-0 italic">
                                                                {data.NotasPrivadas ===
                                                                ""
                                                                    ? "No tiene notas privadas"
                                                                    : JSON.stringify(
                                                                            data.NotasPrivadas
                                                                        )}
                                                            </dd>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })()}
                                    </div>
                                    <div className="mt-5 sm:mt-10 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(!open)}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
